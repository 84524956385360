<template>
  <div class="why-choose-us">
    <h2 class="title">{{ $t('why_choose_us_title') }}</h2>
    <p class="desc">
      {{ $t('why_choose_us_description') }}
    </p>
    <div class="row">
      <div class="col-12 col-lg-6 mb-4" style="min-height:260px;">
        <div class="intro-bg" :style="{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }">
          <a id="play-video" class="video-play-button" :href="videoUrl" target="_blank"></a>
        </div>
      </div>
      <div class="col-md-6">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: activeTab === 'vision' }"
              @click="activeTab = 'vision'"
            >رؤيتنا</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: activeTab === 'mission' }"
              @click="activeTab = 'mission'"
            >رسالتنا</a>
          </li>
        </ul>
        <div class="tab-content">
          <transition name="fade" mode="out-in">
            <div :key="activeTab">
              <p v-if="activeTab === 'vision'" class="tab-p"> {{ $t('vision_description') }}</p>
              <p v-if="activeTab === 'mission'" class="tab-p">{{ $t('mission_description') }}</p>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeTab: 'vision',
      backgroundImage: require('@/assets/img/about-page/628fdc7dcad22.jpg'),
    }
  },
}
</script>
